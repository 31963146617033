import clsx from "clsx"
import classes from "./DateInput.module.scss"

interface TextInputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  > {
  label?: string
  placeholder?: string
  error?: string
  helper?: string

  onChange?(value: any): void
}

const DateInput = (props: TextInputProps) => {
  const { placeholder, error, helper, label, onChange, ...rest } = props

  const handleChange: React.FormEventHandler<HTMLInputElement> = (e) => {
    const value = e.currentTarget.value
    onChange?.(value)
  }

  return (
    <div className={classes.root}>
      {label && <label htmlFor={rest.id} className={classes.label}>{label} {rest.required && <span className={classes.required}>*</span>}</label>}
      <div className={clsx(classes.inputWrapper, {
        [classes.errorInput]: !!error
      })}>
        <input
          type="date"
          placeholder={placeholder}
          className={clsx(classes.input)}
          onInput={handleChange}
          {...rest}
        />
        {/*{rest.required && <span className={classes.required}>שדה חובה</span>}*/}
      </div>
      {error && <p className={classes.error}>{error}</p>}
      {helper && !error && <p className={classes.helper}>{helper}</p>}
    </div>
  )
}

export default DateInput
