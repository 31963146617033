import { BASE_URL, post } from "./requests"

const MIGRATE_FROM_ALPHA = `${BASE_URL}/teacher/migrate/alpha`
const MIGRATE_FROM_ADI_MOBILE = `${BASE_URL}/teacher/migrate/adi`

export const migrateFromAlpha = async (
  email: string,
  password: string,
  teacherUID: number
) => {
  const res = await post(MIGRATE_FROM_ALPHA, { email, password, teacherUID })
  return res
}
export const migrateFromAdiMobile = async (
  username: string,
  password: string,
  teacherUID: number
) => {
  const res = await post(MIGRATE_FROM_ADI_MOBILE, { username, password, teacherUID })
  return res
}
