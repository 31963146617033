import { useParams } from "react-router-dom"
import classes from "./Sign.module.scss"
import Header from "../../components/Header/Header"
import clsx from "clsx"
import { useCopies } from "../../contexts/CopiesContext"
import { useEffect, useRef, useState } from "react"
import { BASE_URL, get, post } from "../../utils/requests"
import { template } from "lodash"
import SignatureCanvas from "react-signature-canvas"
import Button from "../../components/Button/Button"
import blueLogo from "../../assets/blue-logo.svg"
import { track } from "../../utils/track"

interface SignatureRequest {
  id: number
  title: string
  item: string
  signedBy: number
  signedFor: number
  createdAt: string
  documentId: number
  signedByName: string
  content: string
  documentUrl: string
}

const Sign = () => {
  const [signatureRequestDetails, setSignatureRequestDetails] =
    useState<SignatureRequest>({
      id: 0,
      title: "",
      item: "",
      signedBy: 0,
      signedFor: 0,
      createdAt: "",
      documentId: 0,
      signedByName: "",
      content: "",
      documentUrl: ""
    })
  const [canvasWidth, setCanvasWidth] = useState<number>(450)
  const [loading, setLoading] = useState<boolean>(true)

  const [error, setError] = useState<string>("")
  const canvasWrapperRef = useRef(null)
  const canvasRef = useRef<SignatureCanvas>(null)
  const params = useParams<"id">()

  const {
    copies: { sign: copies }
  } = useCopies()

  const id = params.id

  if (!id) {
    window.location.replace("/")
  }

  const updateCanvasWidth = () => {
    const width = ((canvasWrapperRef?.current?.clientWidth ?? 400) -
      48) as number
    setCanvasWidth(width)
  }

  useEffect(() => {
    const fetchRequest = async () => {
      const res = await get<SignatureRequest>(
        `${BASE_URL}/sign/request?id=${encodeURIComponent(id)}`
      )
      if (!!res.id && res.id > 0) {
        setSignatureRequestDetails(res)
        track("Signature Request Loaded", res)
      }
      setLoading(false)
    }
    fetchRequest()

    window.addEventListener("resize", updateCanvasWidth)

    return () => {
      window.removeEventListener("resize", updateCanvasWidth)
    }
  }, [])

  useEffect(() => {
    updateCanvasWidth()
  }, [signatureRequestDetails])

  const onClear = () => {
    setError("")
    track("Signature Cleared")
    canvasRef.current.clear()
  }

  const onDone = async () => {
    setError("")
    const isEmpty = canvasRef?.current?.isEmpty()
    if (isEmpty) {
      track("Signature Error", {
        error: {
          message: "יש לחתום על פד החתימה וללחוץ שמירה"
        }
      })
      setError("יש לחתום על פד החתימה וללחוץ שמירה")
      return
    }

    setLoading(true)
    const pad = canvasRef.current.getTrimmedCanvas()
    const signature = pad.toDataURL("image/png", 1.0)

    track("Signature Submit Start")

    try {
      await post(`${BASE_URL}/sign/request/sign`, {
        ...signatureRequestDetails,
        id,
        signatureImage: signature
      })
      track("Signature Submitted")
      window.location.reload()
      setLoading(false)
    } catch (err) {
      track("Signature Error", { error: err })
      setLoading(false)
    }
  }

  const onViewDocumentPress = () => {
    if (signatureRequestDetails.documentUrl) {
      window.open(signatureRequestDetails.documentUrl, "_blank")
    }
  }

  if (
    !signatureRequestDetails ||
    !signatureRequestDetails.id ||
    signatureRequestDetails.id === 0
  ) {
    return (
      <div className={classes.root}>
        <div className={clsx(classes.header, "padded")}>
          <a
            className={classes.logoWrapper}
            href="https://diodrive.co.il"
            target="_blank"
          >
            <img src={blueLogo} className={classes.logo} />
            <div className={classes.dioTexts}>
              <h1>Dio</h1>
              <p>מעכשיו פשוט יותר להיות מורה נהיגה</p>
            </div>
          </a>
          <Header
            title={copies.signed_title}
            subtitle={copies.signed_subtitle}
          />
        </div>
      </div>
    )
  }
  return (
    <div className={classes.root}>
      <div className={clsx(classes.header, "padded")}>
        <a
          className={classes.logoWrapper}
          href="https://diodrive.co.il"
          target="_blank"
        >
          <img src={blueLogo} className={classes.logo} />
          <div className={classes.dioTexts}>
            <h1>Dio</h1>
            <p>מעכשיו פשוט יותר להיות מורה נהיגה</p>
          </div>
        </a>
        <Header
          title={template(copies.title)({
            name: signatureRequestDetails.signedByName
          })}
          subtitle={template(copies.subtitle)({
            documentTitle: signatureRequestDetails.title
          })}
        />

      </div>
      <div className={clsx(classes.content, "padded")} ref={canvasWrapperRef}>
        <SignatureCanvas
          ref={canvasRef}
          penColor="black"
          canvasProps={{
            width: canvasWidth,
            height: 200,
            className: "sigCanvas"
          }}
        />
        <div className={classes.actions}>
          <Button onClick={onDone} loading={loading}>
            {copies.done_cta}
          </Button>
          <Button onClick={onClear} variant="outlined" loading={loading}>
            {copies.clear_cta}
          </Button>
        </div>
        {signatureRequestDetails.documentUrl && (
          <Button variant="secondary" onClick={onViewDocumentPress}>לצפיה במסמך לחץ כאן</Button>
        )}
        {error && <div className={classes.error}>{error}</div>}
      </div>
    </div>
  )
}

export default Sign
