import clsx from "clsx"
import classes from "./TextInput.module.scss"
import { setTime } from "../../utils/time"

interface TextInputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  > {
  label?: string
  placeholder?: string
  error?: string
  helper?: string

  onChange?(value: any): void

  onFileChange?(file: any): void
}

const TextInput = (props: TextInputProps) => {
  const { placeholder, error, helper, label, onChange, onFileChange, ...rest } = props

  const handleChange: React.FormEventHandler<HTMLInputElement> = (e) => {
    const value = e.currentTarget.value

    if (rest.type === "file") {
      onFileChange?.(e.currentTarget.files[0])
      console.log("file", e.currentTarget.files[0], !!onFileChange)
    }
    onChange?.(value)
  }

  return (
    <div className={classes.root}>
      {label && <label htmlFor={rest.id} className={classes.label}>{label} {rest.required && <span className={classes.required}>*</span>}</label>}
      <div className={clsx (classes.inputWrapper, { [classes.errorInput]: !!error })}>
        <input
          type="text"
          placeholder={placeholder}
          className={clsx(classes.input)}
          onInput={handleChange}
          {...rest}
        />
        {/*{rest.required && <span className={classes.required}>שדה חובה</span>}*/}
      </div>
      {error && <p className={classes.error}>{error}</p>}
      {helper && !error && <p className={classes.helper}>{helper}</p>}
    </div>
  )
}

export default TextInput
