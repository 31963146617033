import React from "react"
import classes from "./Button.module.scss"
import clsx from "clsx"

type ButtonVariants = "primary" | "secondary" | "outlined" | "white" | "text"

interface ButtonProps {
  onClick?(): void
  id?: string
  variant?: ButtonVariants
  children: React.ReactNode
  loading?: boolean
  disabled?: boolean
  startIcon?: React.ReactNode
  className?: string
  size?: "default" | "small"
  color?: "primary" | "error" | "white"
}

const Button = (props: ButtonProps) => {
  const {
    id,
    onClick,
    children,
    className,
    startIcon,
    size = "default",
    color = "primary",
    loading = false,
    disabled = false,
    variant = "primary",
  } = props

  const handleClick = () => {
    if (loading || disabled) {
      return
    }

    onClick?.()
  }
  return (
    <div
      id={id}
      className={clsx(
        classes.root,
        {
          [classes.disabled]: loading || disabled,
          [classes.primary]: variant === "primary",
          [classes.secondary]: variant === "secondary",
          [classes.outlined]: variant === "outlined",
          [classes.white]: variant === "white",
          [classes.text]: variant === "text",
          [classes.colorPrimary]: color === "primary",
          [classes.colorError]: color === "error",
          [classes.colorWhite]: color === "white",
          [classes.small]: size === "small",
        },
        className
      )}
      onClick={handleClick}
    >
      {startIcon && startIcon}
      {typeof children === "string" ? (
        <span className={classes.label}>{children}</span>
      ) : (
        children
      )}
    </div>
  )
}

export default Button
