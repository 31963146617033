import classes from "./FormBuilder.module.scss"
import TextInput from "../TextInput/TextInput"
import DateInput from "../DateInput/DateInput"
import Button from "../Button/Button"
import ReCAPTCHA from "react-google-recaptcha"
import clsx from "clsx"

type FieldTypes = React.HTMLInputTypeAttribute

interface FormField {
  id: string
  type: FieldTypes
  label: string
  name: string
  required?: boolean
  accepts?: HTMLInputElement["accept"]
}

export interface FieldsGroup {
  id: string
  label: string
  fields: FormField[]
}


interface FormBuilderProps {
  groups: FieldsGroup[]
  values: object
  onChange: React.Dispatch<React.SetStateAction<object>>
  onErrorsChange?: React.Dispatch<React.SetStateAction<Record<string, string>>>

  buttonLabel?: string

  onSubmit: (values: object) => void
  errors?: Record<string, string>

  withCaptcha?: boolean
}

const FormBuilder = (props: FormBuilderProps) => {
  const {
    groups,
    values,
    errors,
    onErrorsChange,
    withCaptcha = true,
    buttonLabel = "שליחה",
    onChange,
    onSubmit
  } = props

  const captchaKey = JSON.stringify( errors)

  const onFieldChange = (name: string) => (value: any) => {
    onErrorsChange(currentErrors => ({
      ...currentErrors,
      [name]: null,
      general: null
    }))
    onChange(prevValues => ({ ...prevValues, [name]: value }))
  }


  return <form className={classes.root}>
    {groups.map((group) => <div className={classes.formGroup}>
      <h2 key={group.id}>{group.label}</h2>
      {group.fields.map((field) => {
          const error = errors[field.id]


          if (field.type === "date") {
            return <DateInput key={field.id} label={field.label} required={field.required} value={values[field.id] ?? ""}
                              onChange={onFieldChange(field.name)} id={field.id} error={error} />
          }
          if (field.type === "checkbox") {
            return <>
              <div className={clsx(classes.checkboxWrapper, { [classes.error]: error })} id={field.id}>
                {field.label && <label htmlFor={field.id} className={classes.label}>{field.label}</label>}

                <div className={classes.buttons}>
                  <Button variant={values[field.name] ? "primary" : "outlined"}
                          size="small"
                          onClick={() => onFieldChange(field.name)(true)}>כן</Button>
                  <Button variant={!values[field.name] ? "primary" : "outlined"}
                          size="small"
                          onClick={() => onFieldChange(field.name)(false)}>לא</Button>
                </div>
              </div>
              {error && <p className={classes.error}>{error}</p>}
            </>
          }
          return <TextInput key={field.id} label={field.label} required={field.required} value={values[field.id] ?? ""}
                            type={field.type}
                            id={field.id}
                            accept={field.accepts}
                            onFileChange={onFieldChange(`${field.name}File`)}
                            onChange={onFieldChange(field.name)} error={error} />
        }
      )}
    </div>)}

    {withCaptcha && <div className={classes.captcha}>
      <p>נשמח לדעת שאתם לא בוט 🤖</p>
      <ReCAPTCHA
        key={`captcha-${captchaKey}`}
        sitekey="6LdRefUqAAAAALK1EX81FqvhaxXKDhVbjV1ky7o3"
        onChange={onFieldChange("captchaToken")}
      />
    </div>}

    <div className={classes.buttonWrapper}>
      <Button onClick={() => onSubmit(values)} id="submit-form">{buttonLabel}</Button>
      {errors?.general && (
        <p className={classes.error}>{errors.general}</p>
      )}
    </div>
  </form>
}
export default FormBuilder