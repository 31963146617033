import axios from "axios"
import { getCookie } from "./cookies"

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "https://api.drivewithdio.com"

export const post = async <T = any>(url: string, body?: object): Promise<T> => {
  try {
    const token = getCookie("token")
    const appType = getCookie("appType")
    const res = await axios.post(url, body, {
      headers: {
        "X-App-Type": appType?? "student",
        "X-App-Platform": "Web",
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    })

    return res.data as T
  } catch (err) {
    // @ts-ignore
    return err?.["response"]?.["data"]
  }
}
export const get = async <T = any>(url: string): Promise<T> => {
  try {
    const token = getCookie("token")
    const res = await axios.get(url, {
      headers: {
        "X-App-Type": "student",
        "X-App-Platform": "Web",
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    })

    return res.data as T
  } catch (err) {
    // @ts-ignore
    return err?.["response"]?.["data"]
  }
}
