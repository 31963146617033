import { useEffect, useState } from "react"
import classes from "./MigrateFromAlpha.module.scss"
import PhoneNumber from "../../login/PhoneNumber"
import VerifyOTP from "../../login/VerifyOTP"
import { useSettings } from "../../../contexts/SettingsContext"
import Header from "../../../components/Header/Header"
import TextInput from "../../../components/TextInput/TextInput"
import Button from "../../../components/Button/Button"
import { migrateFromAlpha } from "../../../utils/migrations"
import clsx from "clsx"
import blueLogo from "../../../assets/blue-logo.svg"
import { setCookie } from "../../../utils/cookies"

const MigrateFromAlpha = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [step, setStep] = useState(0)

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")

  const [generalError, setGeneralError] = useState("")

  const {
    settings: { token, teacher },
  } = useSettings()

  const onNextStep = () => {
    setStep((prevStep) => prevStep + 1)
  }

  useEffect(() => {
    if (step === 0 && !!token) {
      setStep(2)
    }
  }, [token, step])
  useEffect(() => {
    setCookie("appType", "teacher")
    if (!!teacher && !!teacher?.migrated) {
      setSuccess(true)
    }
  }, [teacher])

  const onSubmit = async () => {
    setLoading(true)
    setEmailError("")
    setPasswordError("")
    if (!teacher.uid) {
      setLoading(false)
      return setStep(0)
    }
    if (!email) {
      setEmailError("שדה חובה")
    }
    if (!password) {
      setPasswordError("שדה חובה")
    }
    if (!email || !password) {
      return setLoading(false)
    }

    const res = await migrateFromAlpha(email, password, teacher.uid)
    setLoading(false)
    if (res.success) {
      setSuccess(true)
    } else {
      if (res?.error?.field === "email") {
        setEmailError(res.error.message)
        return
      } else {
        setGeneralError(res.error.message)
        return
      }
    }
  }

  if (success) {
    return (
      <>
        <div className={classes.root}>
          <a
            className={classes.logoWrapper}
            href="https://diodrive.co.il"
            target="_blank"
          >
            <img src={blueLogo} className={classes.logo} />
            <div className={classes.dioTexts}>
              <h1>Dio</h1>
              <p>מעכשיו פשוט יותר להיות מורה נהיגה</p>
            </div>
          </a>

          <Header
            title="הנתונים הועברו בהצלחה"
            subtitle="עכשיו אפשר להיכנס לאפליקציית Dio ולהמשיך מאיפה שעצרת"
          />
        </div>
      </>
    )
  }
  return (
    <>
      {step === 0 && (
        <PhoneNumber
          title="התחבר כדי להמשיך"
          subtitle="כדי שנוכל להבין לאיזה חשבון אתה רוצה להעביר את הנתונים"
          onSuccess={onNextStep}
        />
      )}
      {step === 1 && <VerifyOTP onSuccess={onNextStep} />}
      {step === 2 && (
        <div className={classes.root}>
          <a
            className={classes.logoWrapper}
            href="https://diodrive.co.il"
            target="_blank"
          >
            <img src={blueLogo} className={classes.logo} />
            <div className={classes.dioTexts}>
              <h1>Dio</h1>
              <p>מעכשיו פשוט יותר להיות מורה נהיגה</p>
            </div>
          </a>

          <Header
            title="העברת נתונים"
            subtitle={`שלום ${teacher?.firstName}, אנא מלא את הטופס הבא לשם העברת הנתונים שלך לאפליקציית Dio `}
          />

          <div className={classes.wrapper}>
            <div className={classes.content}>
              <TextInput
                placeholder="שם משתמש באלפא דרייב"
                value={email}
                type="email"
                onChange={setEmail}
                error={emailError}
              />

              <TextInput
                placeholder="סיסמא באלפא דרייב"
                value={password}
                onChange={setPassword}
                error={passwordError}
              />

              {generalError && <p className={classes.error}>{generalError}</p>}
              <p className={classes.disclaimer}>
                שימו לב, הפרטים שתזינו בטופס לא נשמרים בשום מקום. בלחיצה על
                ״העברת הנתונים״ יתבצע תהליך אוטומטי שבסופו המידע יעבור
                לאפליקציית Dio Drive. בשום שלב שם המשתמש והסיסמה לא ישמרו.
              </p>
            </div>
            <div className={classes.buttonsWrapper}>
              <Button onClick={onSubmit} loading={loading}>
                העברת הנתונים
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default MigrateFromAlpha
